<template>
  <slot />
</template>
<script setup>
import { ref } from 'vue'
const socket = new WebSocket("wss://socket11.cdhome.cc?name=Minhpn")

const ready = new Promise((resolve, reject) => {
  socket.onopen = resolve;
  ws.onerror = reject;
  ws.onclose = reject;
});
await ready;

const connect = () => {
  console.log('Connect');
}
defineExpose({
  socket,
  connect
})
</script>